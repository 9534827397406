import isEmptyObject from '@/asset/js/isEmptyObject';
import { allBorderStyle } from '@/asset/js/downloadExcel/common/commonStyle';
import setSpecificCellColor from '@/asset/js/downloadExcel/common/setSpecificCellColor';
import setSpecificCellBolder from '@/asset/js/downloadExcel/common/setSpecificCellBolder';

export default ({
  workSheet,
  table,
  headerStyle,
  startTableRowNum,
  cellColorByValueObj = {},
  leftDataLength,
  cellBolderValues = [],
}) => {
  for (let i = 0; i < table.length; i++) {
    if (!isEmptyObject(table[i])) {
      for (let j = 0; j < Object.keys(table[0]).length; j++) {
        const columnIndex = 65 + j;
        let column = String.fromCharCode(65 + j);

        if (columnIndex > 90) {
          column = String.fromCharCode(65) + String.fromCharCode(65 + j - 26);
        }

        const cell = column + (i + startTableRowNum + 1);
        workSheet[cell].s = i === 0 ? headerStyle : {};

        if (leftDataLength && j >= leftDataLength) {
          workSheet[cell].s = { ...workSheet[cell].s, alignment: { horizontal: 'center' } };
        }

        workSheet[cell].s = {
          ...workSheet[cell].s,
          font: {
            ...workSheet[cell].s.font,
            name: 'arial',
          },
          border: allBorderStyle,
          alignment: { ...workSheet[cell].s.alignment, vertical: 'center' },
        };

        setSpecificCellColor(workSheet, cell, cellColorByValueObj);
        setSpecificCellBolder(workSheet, cell, cellBolderValues);
      }
    }
  }
};
