export default (workSheet, cell, values) => {
  values.forEach((val) => {
    if (workSheet[cell].v === val) {
      workSheet[cell].s = {
        ...workSheet[cell].s,
        font: {
          ...workSheet[cell].s.font,
          bold: true,
        },
      };
    }
  });
};
