const borderStyle = {
  style: 'thin',
  color: {
    rgb: '000000',
  },
};

const allBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  right: borderStyle,
  left: borderStyle,
};

const titleStyle = {
  font: {
    sz: 14,
    bold: true,
  },
};

const labelHeaderStyle = {
  fill: {
    fgColor: { rgb: 'd4d4d4' },
  },
  font: {
    bold: true,
  },
};

const tableHeaderStyle = {
  fill: {
    fgColor: { rgb: '3f4860' },
  },
  font: {
    bold: true,
    color: {
      rgb: 'ffffff',
    },
  },
};

const tableHeaderStyleBlue = {
  ...tableHeaderStyle,
  fill: {
    fgColor: { rgb: '5057c1' },
  },
};

export { borderStyle, allBorderStyle, titleStyle, labelHeaderStyle, tableHeaderStyle, tableHeaderStyleBlue };
