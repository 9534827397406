<template>
  <div class="calendar-table">
    <ul class="thead">
      <li v-for="(thead, index) in theadList" :key="index" :style="{ width: `${thead.widthNumPercent}%` }">
        <span>{{ thead.name }}</span>
      </li>
    </ul>
    <div
      class="contents"
      :style="{ width: `${theadList[theadList.length - 1].widthNumPercent}%` }"
      ref="contentsData"
      @scroll="onScrollContentsData"
    >
      <ul class="contents-dates">
        <li v-for="i in lastDate" :key="`${month}-${i}`" :style="{ width: `calc((100% / ${lastDate}))` }">
          {{ month }}/{{ i }}
        </li>
      </ul>
      <ul class="contents-data">
        <slot name="contents-data" />
      </ul>
    </div>
    <div class="contents-label" ref="contentsLabel" @scroll="onScrollContentsLabel">
      <slot name="contents-label" />
    </div>
    <div v-show="!list || list.length === 0" class="list-data-none">
      <loading-bar v-if="!list" />
      <div v-else-if="list.length === 0">
        <slot name="no-list-description" />
      </div>
    </div>
  </div>
</template>

<script>
import getLastDate from '@/asset/js/getLastDate';
import LoadingBar from '@/component/loadingBar/loadingBar';

export default {
  name: 'CalendarTable',
  props: ['theadList', 'selectedDate', 'list'],
  data() {
    return {};
  },
  computed: {
    month() {
      return new Date(this.selectedDate).getMonth() + 1;
    },
    lastDate() {
      return getLastDate(this.selectedDate);
    },
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onScrollContentsLabel(e) {
      this.$refs.contentsData.scrollTop = e.target.scrollTop;
    },
    onScrollContentsData(e) {
      this.$refs.contentsLabel.scrollTop = e.target.scrollTop;
    },
  },
  components: { LoadingBar },
};
</script>

<style scoped lang="scss">
@import 'CalendarTable';
</style>
