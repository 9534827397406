var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rscFile
    ? _c(
        "div",
        {
          staticClass: "action-require-image-box",
          style: _vm.getImageStyle(_vm.rscFile),
        },
        [
          _c("div", { staticClass: "button-wrapper" }, [
            _c(
              "button",
              {
                staticClass: "text-button",
                on: {
                  click: function ($event) {
                    return _vm.togglePopup("ImagePopup")
                  },
                },
              },
              [_vm._v("미리보기")]
            ),
            _c(
              "a",
              {
                staticClass: "text-button",
                attrs: {
                  href: _vm.rscFile.rscUrl,
                  download: _vm.rscFile.name,
                  target: "_blank",
                },
              },
              [_vm._v("다운로드")]
            ),
          ]),
          _c("image-popup", {
            attrs: {
              "is-show": _vm.showedPopupName === "ImagePopup",
              "image-src": _vm.rscFile.rscUrl,
            },
            on: { close: _vm.togglePopup },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }