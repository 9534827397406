var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "popup-wrapper" }, [
          _c(
            "div",
            { staticClass: "popup" },
            [
              _c("img", { attrs: { src: _vm.imageSrc, alt: "photo" } }),
              _c(
                "button-basic",
                {
                  attrs: { color: "primary", circle: "26" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("close-icon")],
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }