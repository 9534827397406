var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-bar-wrapper" }, [
    _c("div", { staticClass: "no-data" }, [
      _c("div", { staticClass: "lds-dual-ring" }),
      _c("p", { staticClass: "mt-20" }, [_vm._v(_vm._s(_vm.loadingText))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }