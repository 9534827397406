<template>
  <transition name="modal" v-if="isShow">
    <div class="popup-wrapper">
      <div class="popup">
        <img :src="imageSrc" alt="photo" />
        <button-basic color="primary" circle="26" @click="$emit('close')">
          <close-icon />
        </button-basic>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'ImagePopup',
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'ImagePopup';
</style>
