var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-play-wrapper" },
    [
      _c("audio", { attrs: { src: _vm.audioSrc, controls: true } }),
      _vm._l(_vm.descriptions, function (desc, index) {
        return _c(
          "p",
          { key: "audio-" + index, staticClass: "info-text" },
          [
            _c("vue-material-icon", { attrs: { name: "info", size: 13 } }),
            _c("span", [_vm._v(_vm._s(desc))]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }