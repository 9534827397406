<template>
  <div class="audio-play-wrapper">
    <audio :src="audioSrc" :controls="true" />
    <p v-for="(desc, index) in descriptions" :key="`audio-${index}`" class="info-text">
      <vue-material-icon name="info" :size="13" />
      <span>{{ desc }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AudioPlay',
  props: ['audioSrc', 'descriptions'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'AudioPlay';
</style>
