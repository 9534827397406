<template>
  <div class="action-require-image-box" v-if="rscFile" :style="getImageStyle(rscFile)">
    <div class="button-wrapper">
      <button class="text-button" @click="togglePopup('ImagePopup')">미리보기</button>
      <a :href="rscFile.rscUrl" :download="rscFile.name" class="text-button" target="_blank">다운로드</a>
    </div>
    <image-popup :is-show="showedPopupName === 'ImagePopup'" :image-src="rscFile.rscUrl" @close="togglePopup" />
  </div>
</template>

<script>
import ImagePopup from '@/component/popup/imagePopup/ImagePopup';

export default {
  name: 'ActionRequireImageBox',
  props: ['rscFile'],
  data() {
    return {
      showedPopupName: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    getImageStyle(rscFile) {
      if (rscFile) {
        return {
          backgroundImage: `url(${rscFile.rscUrl})`,
        };
      }
      return {};
    },
  },
  components: { ImagePopup },
};
</script>

<style scoped lang="scss">
@import 'ActionRequireImageBox';
</style>
