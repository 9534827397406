<template>
  <div class="loading-bar-wrapper">
    <div class="no-data">
      <div class="lds-dual-ring"></div>
      <p class="mt-20">{{ loadingText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    loadingText: {
      type: String,
      default: '목록을 불러오는 중입니다..',
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'LoadingBar';
</style>
