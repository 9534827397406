export default (workSheet, cell, values) => {
  for (let key in values) {
    if (workSheet[cell].v === key) {
      workSheet[cell].s = {
        ...workSheet[cell].s,
        font: {
          ...workSheet[cell].s.font,
          color: {
            rgb: values[key],
          },
        },
      };
    }
  }
};
